<template>
    <div class="category-card" @click="$emit('click')">
        <div class="category-card__picture">
            <img :src="imageSrc(category.picture)">
            <div class="category-card__title">{{ category.title }}</div>
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';

    import { imageSrc, priceFormated, weightFormated } from '../../helpers';

    import BaseButton from '../common/BaseButton';
    import BaseCounter from '../common/BaseCounter';

    export default {
        name: 'ProductCard',
        components: {
            BaseButton,
            BaseCounter,
        },
        props: {
            category: {
                type: Object,
                default: () => {}
            }
        },
        data: () => ({
            imageSrc,
            priceFormated,
            weightFormated,
        }),
        computed: {
            ...mapState('orders', {
                cart: state => state.cart
            })
        },
        methods: {
        }
    }
</script>

<style lang="scss">
    .category-card {
        width: calc(100% - 20px);
        max-width: 295px;
        display: flex;
        position: relative;
        background: #FFFFFF;
        transition: .2s ease box-shadow;
        flex-direction: column;
        border: 5px solid #ffffff;
        border-radius: 7px;
        
        @media screen and (max-width: 1300px) {
            width: calc(33% - 20px);
        }
        
        @media screen and (max-width: 900px) {
            width: calc(50% - 10px);
        }

        &__picture {
            display: flex;
            width: 100%;
            position: relative;
            overflow: hidden;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 5px;

            > img {
                width: 100%;
            }
        }

        &__content {
            padding: 0 12px;
        }
        
        &__title {
            position: absolute;
            bottom: 0;
            right: 0px;
            padding: 5px 30px;
            background: #F36223;
            font-weight: 400;
            white-space: nowrap;
            font-size: 18px;
            line-height: 20px;
            color: #ffffff;
            text-align: center;
            border-radius: 5px 0;
        }
    }
</style>