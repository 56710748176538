<template>
    <BasePopup v-bind="$attrs" v-on="$listeners">
        <div class="product-detail">
            <div class="product-detail__wrap">
                <div class="product-detail__picture">
                    <v-carousel>
                        <v-carousel-item
                            v-for="(item, index) in pictures"
                            :key="`picture-${ index }`"
                            :src="item"
                            reverse-transition="fade-transition"
                            transition="fade-transition"
                        ></v-carousel-item>
                    </v-carousel>
                </div>
                <div class="product-detail__content">
                    <div class="product-detail__code">{{ product.code }}</div>
                    <div class="product-detail__title">{{ product.title }}</div>
                    <div class="product-detail__content__wrap">
                        <div class="product-detail__price">{{ priceFormated(product.price) }}</div>
                    </div>
                    <div class="product-detail__description" v-if="product.composition" v-html="htmlFormatted(product.composition)"></div>
                </div>
            </div>
            <div class="product-detail__description" v-if="product.description" v-html="htmlFormatted(product.description)"/>
            <div class="product-detail__buttons">
                <BaseButton @click.stop="addCartProduct">В корзину</BaseButton>
            </div>
        </div>
    </BasePopup>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';

    import { imageSrc, priceFormated, weightFormated, htmlFormatted } from '../../helpers';
    import BaseButton from '../common/BaseButton';
    import BasePopup from '../common/BasePopup';
    import BaseCheckbox from '../common/BaseCheckbox';
    import BaseRadio from '../common/BaseRadio';

    export default {
        name: 'PopupProductDetail',
        components: {
            BaseButton,
            BasePopup,
            BaseCheckbox,
            BaseRadio,
        },
        props: {
            product: {
                type: Object,
                default: () => {}
            }
        },
        computed: {
            pictures() {
                if(this.product.pictures) {
                    return [
                        this.product.picture,
                        ...this.product.pictures
                    ].map(item => imageSrc(item))
                }
                return [
                    this.product.picture
                ].map(item => imageSrc(item))
            }
        },
        data: () => ({
            imageSrc,
            priceFormated,
            weightFormated,
            htmlFormatted,
        }),
        watch: {
            product: {
                deep: true,
                async handler() {
                    this.product.size_id = this.product.sizes[0].id;
                }
            },
        },
        methods: {
            addCartProduct() {
                this.$emit('updateEntity');
                store.dispatch('orders/addCartProduct', { 
                    product: {
                        ...this.product,
                        quantity: 1,
                        unique_code: `${ this.product.id }-${ this.product.size_id }`
                    }
                });
                this.$emit('close');
            }
        }
    }
</script>

<style lang="scss">
.product-detail {
    padding: 12px;
    position: relative;
    background: #FFFFFF;
    transition: .2s ease box-shadow;
    border-radius: 0;

    &__wrap {
        padding-bottom: 10px;
        display: flex;
        justify-content: space-between;
        align-items: center;
            
        @media screen and (max-width: 460px) {
            display: block;
        }
    }

    &__picture {
        width: 48%;
        overflow: hidden;
        border-radius: 0;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
            
        @media screen and (max-width: 460px) {
            width: 100%;
            margin-top: 24px;
        }
    }

    &__content {
        width: 48%;
        padding: 0 4px;
        margin-top: 14px;
            
        @media screen and (max-width: 460px) {
            width: 100%;
        }

        &__wrap {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }
    }
    
    &__price {
        display: inline-block;
        position: relative;
        font-weight: 400;
        white-space: nowrap;
        font-size: 24px;
        line-height: 27px;
    }
        
    &__code {
        font-size: 14px;
        line-height: 16px;
        margin: 10px 0;
    }
    
    &__title {
        font-size: 24px;
        font-weight: 500;
        line-height: 28px;
        overflow: hidden;
    }
    
    &__description {
        font-size: 14px;
        line-height: 17px;
        padding-top: 20px;
        
    }
    
    &__weight {
        color: #9E9B98;
        margin: 5px 0;
        font-size: 16px;
        line-height: 19px;
    }

    &__groups {
        border-bottom: 1px solid #efefef;

        .v-input--radio-group {
            margin: 0;
            padding: 0;
        }

        &__item {
            padding: 10px 0;
            border-top: 1px solid #efefef;
        }
        &__title {
            font-size: 16px;
            line-height: 19px;
            margin-bottom: 5px;
            color: #F36223;
        }
    }

    &__buttons {
        padding-top: 10px;
    }
}
</style>