<template>
    <div class="product-card" @click="$emit('click')">
        <div class="product-card__picture">
            <img :src="imageSrc(product.picture)">
            <div class="product-card__price">{{ priceFormated(product.price) }}</div>
        </div>
        <div class="product-card__content">
            <div class="product-card__code">{{ product.code }}</div>
            <div class="product-card__title">{{ product.title }}</div>
            <!-- <div class="product-card__buttons" v-if="!hideButtons">
                <BaseCounter
                    v-if="cartQuantity > 0"
                    :value="cartQuantity"
                    :min-value="0"
                    @change="setCartProductQuantity"
                />
                <BaseButton v-else @click.stop="addCartProduct">В корзину</BaseButton>
            </div> -->
        </div>
        <v-spacer />
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';

    import { imageSrc, priceFormated, weightFormated } from '../../helpers';

    import BaseButton from '../common/BaseButton';
    import BaseCounter from '../common/BaseCounter';

    export default {
        name: 'ProductCard',
        components: {
            BaseButton,
            BaseCounter,
        },
        props: {
            product: {
                type: Object,
                default: () => {}
            },
            hideButtons: {
                type: Boolean,
                default: false
            }
        },
        data: () => ({
            imageSrc,
            priceFormated,
            weightFormated,
        }),
        computed: {
            ...mapState('orders', {
                cart: state => state.cart
            }),
            cartQuantity() {
                return this.cart.products.reduce((q, item) => {
                    if(this.product.id === item.product.id) {
                        q += item.quantity;
                    }
                    return q;
                }, 0);
            }
        },
        methods: {
            setCartProductQuantity(quantity) {
                let productIndex = 0, fullQuantity = 0, productQuantity = 0;
                for(const index in this.cart.products) {
                    if(this.cart.products[index].product.id === this.product.id) {
                        fullQuantity += this.cart.products[index].quantity;
                        productIndex = +index;
                        productQuantity = this.cart.products[index].quantity;
                    }
                }
                quantity = quantity - fullQuantity + productQuantity;
                
                if(quantity === 0) {
                    store.dispatch('orders/removeCartProduct', productIndex);
                } else {   
                    store.dispatch('orders/setCartProductQuantity', { quantity, index: productIndex });
                }
            },
            addCartProduct() {
                if(this.product.groups && this.product.groups.length > 0) {
                    this.$emit('click');
                } else {
                    this.$emit('updateEntity');
                    store.dispatch('orders/addCartProduct', { 
                        product: {
                            ...this.product,
                            quantity: 1,
                            unique_code: `${ this.product.id }`
                        }
                    });
                }
            }
        }
    }
</script>

<style lang="scss">
    .product-card {
        width: calc(100% - 20px);
        max-width: 400px;
        display: flex;
        position: relative;
        background: #FFFFFF;
        transition: .2s ease box-shadow;
        flex-direction: column;
        border: 5px solid #ffffff;
        border-radius: 7px;
        
        @media screen and (max-width: 1300px) {
            width: calc(33% - 20px);
        }
        
        @media screen and (max-width: 900px) {
            width: calc(50% - 10px);
        }

        &__picture {
            display: flex;
            width: 100%;
            position: relative;
            overflow: hidden;
            background-position: center center;
            background-repeat: no-repeat;
            background-size: cover;
            border-radius: 5px;

            > img {
                width: 100%;
            }
        }

        &__content {
            padding: 0 12px;
        }
        
        &__price {
            position: absolute;
            bottom: 0;
            right: 0px;
            padding: 5px 30px;
            background: #F36223;
            font-weight: 400;
            white-space: nowrap;
            font-size: 20px;
            line-height: 22px;
            color: #ffffff;
            text-align: center;
            border-radius: 5px 0;
            // transform: rotate(-4deg);

            @media screen and (max-width: 690px) {
                font-size: 16px;
                line-height: 18px;
            }
        }
        
        &__code {
            font-size: 14px;
            line-height: 16px;
            margin: 10px 0;

            @media screen and (max-width: 690px) {
                font-size: 12px;
                line-height: 14px;
            }
        }
        
        &__title {
            font-size: 20px;
            line-height: 22px;
            margin: 5px 0;

            @media screen and (max-width: 690px) {
                font-size: 16px;
                line-height: 18px;
            }
        }
        
        &__weight {
            color: #9E9B98;
            margin: 5px 0;
            font-size: 16px;
            line-height: 19px;
        }

        &__buttons {

        }
    }
</style>