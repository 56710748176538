<template>
    <div class="home">
        <v-progress-circular
            indeterminate
            color="primary"
            v-show="!loaded"
            class="fixed__preloader"
        ></v-progress-circular>
        <div class="home__container" v-show="loaded">
            <div class="about">
                <div class="about__title">О компании</div>
                <div class="about__ancor" id="about"></div>
                <div class="about__wrapper">
                    <div class="about__text">
                        <p><b>Уважаемые клиенты и бренды!</b></p>
                        <p>
                            Мы рады приветствовать вас на нашем сайте, где вы сможете ознакомиться с услугами нашей компании по пошиву одежды для брендирования на заказ. 
                            Мы предлагаем вам уникальную возможность создать собственный стиль и подчеркнуть свою корпоративную идентичность через наши индивидуальные решения.
                        </p>
                        <p>
                            Наша компания специализируется на пошиве на заказ, предоставляя вам возможность создавать одежду, которая полностью соответствует вашим потребностям и требованиям. 
                            Независимо от того, нужны ли вам фирменные формы для вашей команды, корпоративная одежда для сотрудников или оригинальные элементы брендирования, мы готовы воплотить ваши идеи в реальность.
                        </p>
                        <p>
                            Мы понимаем, что каждый бренд имеет свою уникальность и характер, и поэтому наша команда дизайнеров работает с вами непосредственно, чтобы создать концепцию, отражающую вашу уникальность. 
                            Мы используем только лучшие материалы и высокотехнологичное оборудование, чтобы обеспечить высокое качество и долговечность вашей брендированной одежды.
                        </p>
                        <!-- <p>
                            На нашем сайте вы  сможете ознакомиться с нашим портфолио и отзывами клиентов. 
                            Мы также предлагаем удобные способы связи, чтобы вы могли обсудить свои требования и задать любые вопросы.
                        </p> -->
                        <p>
                            Благодарим вас за проявленный интерес к нашей компании. 
                            Мы с нетерпением ждем возможности создать для вас уникальную и выразительную брендированную одежду, которая станет отражением вашего стиля и поможет выделиться среди конкуренции.
                        </p>
                        <p><b>Добро пожаловать в мир индивидуального стиля и брендирования!</b></p>
                    </div>
                    <div class="about__image">
                        <img src="../assets/main.jpg">
                    </div>
                </div>
            </div>
            <div class="home__item">
                <div class="home__item__title">Каталог</div>
                <div class="home__item__ancor" id="catalog"></div>
                <div class="home__categories-list">
                    <template v-for="category in categories">
                        <CategoryCard
                            v-if="!category.parent_id"
                            :key="`category-${category.id}`"
                            :category="category"
                            @click="openCategory(category)"
                        />
                    </template>
                </div>  
            </div>
            <!-- <template v-for="category in categories">
                <div class="home__item" :key="`category-${category.id}`">
                    <div class="home__item__ancor" :id="category.code"></div>
                    <div class="home__item__title">{{ category.title }}</div>
                    <div class="home__categories-list">
                        <template v-for="product in category.products">
                            <ProductCard
                                :key="`product-${product.id}`"
                                :product="product"
                                @click="open(product)"
                            />
                        </template>
                    </div>
                </div>
            </template> -->

            <!-- <PopupProductDetail
                v-model="showDetails"
                :product="activeProduct"
                @close="closeDetails"
                @updateEntity="updateEntity"
            /> -->
        </div>
    </div>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';

    import ProductCard from '../components/entity/ProductCard';
    import CategoryCard from '../components/entity/CategoryCard';
    import PopupProductDetail from '../components/entity/PopupProductDetail';

    export default {
        name: 'Entity',
        metaInfo() {
            return {
                title: 'Главная'
            }
        },
        components: {
            ProductCard,
            CategoryCard,
            PopupProductDetail,
        },
        data: () => ({
            showDetails: false,
            activeProduct: {},
            loaded: false
        }),
        computed: {
            ...mapState('categories', {
                categories: state => state.entities
            }),
        },
        async mounted() {
            await store.dispatch('categories/fetch', {});
            await new Promise((resolve, reject) => setTimeout(resolve, 300));
            this.loaded = true;
        },
        methods: {
            open(product) {
                this.$router.push(`/product/${ product.code }`);
            },
            openCategory(category) {
                this.$router.push(`/category/${ category.code }`);
            },
            details(product) {
                this.activeProduct = product;
                this.showDetails = true;
            },
            closeDetails() {
                this.showDetails = false;
            },
            setCategory(id) {
                this.activeCategory = +id;
            }
        }
    }
</script>

<style lang="scss">
    .home {
        width: 100%;
        max-width: 1240px;
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin: 0 auto;

        .about {
            position: relative;
            padding-bottom: 20px;

            &__title {
                padding: 10px;
                font-size: 24px;
                line-height: 30px;
                font-weight: 400;
            }

            &__ancor {
                position: absolute;
                top: -80px;
            }

            &__wrapper {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                background: #FFFFFF;
                transition: .2s ease box-shadow;
                border: 5px solid #ffffff;
                border-radius: 7px;
                padding: 20px 20px 10px 20px;

                @media screen and (max-width: 500px) {
                    flex-direction: column-reverse;
                    // display: none;
                }

                @media screen and (max-width: 890px) {
                    padding: 10px 10px 0 10px;
                }
            }

            &__image {
                padding-left: 20px;

                img {
                    width: 100%;
                    border-radius: 5px;
                }

                @media screen and (max-width: 500px) {
                    padding: 0;
                }
            }

            p {
                font-size: 16px;
                margin-bottom: 10px;
            
                @media screen and (max-width: 890px) {
                    font-size: 14px;
                }
            }
        }

        &__container {
            width: 100%;
            max-width: 1240px;
        }
        
        &__item {
            position: relative;

            &__title {
                padding: 10px;
                font-size: 24px;
                line-height: 30px;
                font-weight: 400;
            }

            &__ancor {
                position: absolute;
                top: -80px;
            }
        }

        &__categories-list {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            padding: 10px 0 20px 0;
            margin-right: -20px;
            
            @media screen and (max-width: 890px) {
                margin-right: -10px;
            }

            > div {
                margin: 0 20px 20px 0;
        
                @media screen and (max-width: 890px) {
                    margin: 0 10px 10px 0;
                }
            }
        }
    }
</style>
